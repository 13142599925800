<template>
  <div class="guide-main pdHeader">
    <common-header class="orange"></common-header>
    <div class="forms">
      <div class="form-item">
        <div class="name-box">
          <span class="name">手机号</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box">
          <input
            type="text"
            placeholder="请输入手机号"
            class="input"
            maxlength="11"
            v-model="phone"
            @blur="fixScroll"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="name-box">
          <span class="name">验证码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box flex centre3">
          <div class="fe">
            <input
              type="text"
              placeholder="请输入验证码"
              class="input"
              v-model="verifyCode"
              maxlength="6"
              @blur="fixScroll"
            />
          </div>
          <div @click="send">
            <span :class="`yzm ${verifyClass}`">{{ verifyText }}</span>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="name-box">
          <span class="name">密码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box">
          <input
            type="password"
            placeholder="请输入密码"
            class="input"
            v-model="pwd"
            @blur="fixScroll"
          />
        </div>
      </div>
      <div class="form-item mb0">
        <div class="name-box">
          <span class="name">推荐人手机号</span>
        </div>
        <div class="input-box">
          <input
            type="password"
            placeholder="推荐人手机号"
            class="input"
            v-model="referrerPhone"
            @blur="fixScroll"
          />
        </div>
      </div>

      <div class="form-btns">
        <a href="javascript:" class="form-item-btn" @click="register">确定</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { isPhone, isCode, fixScroll } from "@/utils/utils";
import { getPreKey } from "@/utils/codeVerify";
import { setCookies, isWeiXin } from "@/utils/common";

let timer = 60;
let inter;
export default {
  name: "Register",
  data() {
    this.fixScroll = () => fixScroll();

    return {
      info: {},
      // 验证码需要 start
      verifyClass: "",
      verifyText: "获取验证码",
      phone: "",
      verifyCode: "",
      referrerPhone: "",
      // 验证码需要 end
      referrerId: "",
      pwd: "",
      userNickName: ""
    };
  },
  mounted() {},
  methods: {
    ...mapActions("account", ["bindPhone"]),
    // 获取验证码 start
    send() {
      if (this.verifyClass === "disabled") {
        return;
      }
      if (this.phone === "") {
        this.$toast("手机号不能为空");
        return;
      }
      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      this.sendTimer();
      inter = setInterval(this.sendTimer, 1000);

      getPreKey(
        {
          phone: this.phone,
          type: 4
        },
        v => {
          // console.log(v);
          this.verifyCode = v.verifyCode || "";
        },
        () => {
          this.clearTimer();
        }
      );
    },
    clearTimer() {
      timer = 60;
      this.verifyClass = "";
      this.verifyText = "获取验证码";
      clearInterval(inter);
    },
    sendTimer() {
      timer--;
      if (timer < 1) {
        this.verifyClass = "";
        this.verifyText = "获取验证码";
        clearInterval(inter);
      } else {
        this.verifyClass = "disabled";
        this.verifyText = timer + "s";
      }
    },
    // 获取验证码 end
    register() {
      if (this.phone === "") {
        this.$toast("手机号不能为空");
        return;
      }
      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      if (this.verifyCode === "") {
        this.$toast("验证码不能为空");
        return;
      }
      if (!isCode(this.verifyCode)) {
        this.$toast("验证码格式不正确");
        return;
      }
      if (this.pwd === "") {
        this.$toast("密码不能为空");
        return;
      }

      const da = {
        phone: this.phone,
        pwd: this.pwd,
        verifyCode: this.verifyCode,
        referrerPhone: this.referrerPhone,
      };

      this.bindPhone(da).then(res => {
        this.$toast("绑定成功");
        setCookies(res.data);
        clearInterval(inter);
        const _this = this;
        setTimeout(() => {
          _this.$router.back(-1);
        }, 2500);
      });
    }
  }
};
</script>
<style lang="less" scoped>
.forms {
  padding: 0.4rem 0.3rem;
}

.form-btns {
  margin-top: 1rem;
}
</style>
